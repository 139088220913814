import { BillingPeriod } from './models/insurance-models/InsuranceBillingPeriodModel'
import { USER_POLICY_STATUS } from './models/user-models/UserStatus'

export const VERSION = 'v 2.0.0'

export const SKYWATCH_API_VERSION = 3

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const ESTIMATED_MILAGE_OPTIONS = {
  '1,000': 1000,
  '2,000': 2000,
  '3,000': 3000,
  '4,000': 4000,
  '5,000': 5000,
  '6,000 or more': 6000,
}

export const APPSTORE_URL = 'https://apps.apple.com/il/app/voom-motorcycle-insurance/id1641498413'

export const PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=com.voom_motorcycle_app&hl=en&gl=US'

export const IS_ACH_ACTIVE = false
export const IS_MVR_ACTIVE = true

export const STATE_EXPIRY_TTL = 1000 * 60 * 60 * 24 * 2 // 2 days

export const PAYMENT_METHODS = { 'Credit/Debit': 'Credit/Debit', 'Apple pay': 'Apple pay', 'Google pay': 'Google pay', Bank: 'ACH Payment' }

export const WALLETS = ['Apple pay', 'Google pay']

export const THREE_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 7 * 3

export const MAX_FUTURE_DATE_DAYS = 60

export const PHONE_NUMBER_REGEX = /^[0-9*\+-]+$/

export const COUNTRIES = { US: 'US', CANADA: 'Canada', UK: 'UK' }

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const WEIRD_GENERATED_GUID = '00000000-0000-0000-0000-000000000000'

export const HULL_MAX_LENGTH_SERIAL_NUMBER = 16
export const HULL_MIN_LENGTH_SERIAL_NUMBER = 6
export const UK_HULL_MIN_LENGTH_SERIAL_NUMBER = 1

export const PAYMENT_FEE = 2

export const VIN_EXPLANATIONS =
  "VINs are usually stamped on the steering neck. If it's not located there, you can find it etched on the motor. Move the handlebars to create a clear view of the right side of the frame, where the steering attaches. The VIN will appear at the bottom of the cylinder."
export const MOTORCYCLE_ENDORESMENTS =
  'Our team will review your motorcycle endorsement. If the form is found to be non-compliant with legal requirements, it may lead to the initiation of policy cancellations.'

export const VIOLATIONS = [
  'Animal Collision',
  'At Fault Accident',
  'At Fault Accident Bodily Injury To Others',
  'At Fault Accident Physical Damage Only',
  'Attempting To Elude Officer',
  'Backing Illegally',
  'Careless/ Negligent Driving',
  'Cellular Telephone Infraction',
  'Comprehensive Claim',
  'Defective Equipment',
  'Deviating Lane Of Traffic',
  'Drive Under Influence Of Alcohol/ Drug',
  'Driving Against Traffic',
  'Driving Too Fast For Conditions',
  'Fail To Obey Traffic Control',
  'Failure To Control Vehicle',
  'Failure To Dim Lights',
  'Failure To Report Acciden',
  'Failure To Signal',
  'Failure To Stop',
  'Failure To Stop After Accident',
  'Failure To Stop For School Bus',
  'Failure To Yield',
  'Following Too Close',
  'Illegal Riding',
  'Illegal Turn',
  'Impeding Traffic',
  'Implied Consent / Refuse Test',
  'Improper Backing',
  'Improper Signal',
  'Inattentive Driving',
  'Not At Fault Accident',
  'Obstructing Traffic',
  'Obstructing View Or Control',
  'Open Intoxicant',
  'Oper After Revocation / Suspension',
  'Operating Without Lic / Exp Lic',
  'Passing Illegally',
  'Reckless / Negligent Driving',
  'Reckless Homicide',
  'Safety Violation',
  'Single Vehicle Accident',
  'Speeding 09 Over',
  'Speeding 10-19 Over',
  'Speeding 20 Over',
  'Unnecessary Acceleration',
]

export const billing_period_options = {
  [BillingPeriod.MONTH]: {
    label: 'Monthly',
    value: BillingPeriod.MONTH,
    title: `Pay on a monthly basis`,
    gtm: 'monthly_payment_settlement',
    bottomshitText: 'Per month',
    tooltipText: 'The amount shown is for a monthly period (based on your chosen payment recurrence) where the policy is for a full year',
    divider: 12,
  },
  [BillingPeriod.THREEMONTHS]: {
    label: '3 Month',
    value: BillingPeriod.THREEMONTHS,
    feesDiscount: '16',
    title: `Pay only once every 3 months`,
    gtm: '3_months_payment_settlement',
    bottomshitText: 'Per 3 months',
    totalText: 'Total 3 Months',
    tooltipText: 'The amount shown is for a 3 month period (based on your chosen payment recurrence) where the policy is for a full year',
    discountSvg: 'RibbonSelectDiscount16Svg',
    divider: 4,
  },
  [BillingPeriod.SIXMONTHS]: {
    label: '6 Month',
    value: BillingPeriod.SIXMONTHS,
    feesDiscount: '20',
    title: `Pay only once every 6 months`,
    gtm: '6_months_payment_settlement',
    bottomshitText: 'Per 6 months',
    totalText: 'Total 6 Months',
    tooltipText: 'The amount shown is for a 6 month period (based on your chosen payment recurrence) where the policy is for a full year',
    discountSvg: 'RibbonSelectDiscount20Svg',
    divider: 2,
  },
  [BillingPeriod.ANNUAL]: {
    label: 'Annual',
    value: BillingPeriod.ANNUAL,
    feesDiscount: '22',
    title: `Pay once a year`,
    gtm: 'annual_payment_settlement',
    bottomshitText: 'Annual pay',
    totalText: 'Total Annual',
    discountSvg: 'RibbonSelectDiscount22Svg',
    divider: 1,
  },
}

export const INSURER_OPTIONS = [
  'AAA',
  'Allstate',
  'American Family',
  'Dairyland',
  'Farmers',
  'Foremost',
  'GEICO',
  'Nationwide',
  'Progessive',
  'Rider',
  'State Farm',
  'Other',
  'I decline to provide',
]

export const statesWithoutInsuranceHistory = ['TX', 'IN']
export const statesWithLiabilityTripleBundle = ['AZ', 'IN', 'OH', 'IL']
export const statesWithoutLojackDiscount = ['TX', 'TN', 'MO', 'MN', 'NJ', 'MD', 'OR', 'LA', 'FL', 'VA', 'GA', 'NM', 'UT']

export const statesWithoutDiminishingDeductible = ['IN', 'OH', 'AZ', 'IL']

export const stateWithActiveMilitaryDiscount = ['LA']

export const stateWithLimitToOneOperator = ['VA']

export const stateWithoutEducationDiscount = ['GA']

export const stateWithMotorcycleEndoresments = ['LA']

export const statesWithResidenceInsuranceFactor = ['MN', 'NJ']

export const insuranceHistoryInTheLast31dayes = ['TN', 'OR', 'PA', 'LA', 'VA', 'GA', 'NM']
export const statesWithDefaultBillingMonthly = ['TX', 'TN', 'MN', 'OR', 'PA', 'LA', 'VA', 'GA', 'NJ', 'FL', 'UT']

export const estimatedMileageOption = [1000, 1500, 1700, 2000, 2500, 2600, 3000, 3500, 4000, 5000]
export const estimatedNotAllowedForMarkelAffiliate = [1000]
export const activeAccountsStatus = [
  USER_POLICY_STATUS.BOUND,
  USER_POLICY_STATUS.SCHEDULED,
  USER_POLICY_STATUS.CANCELED,
  USER_POLICY_STATUS.EXPIRED,
]

export const estimatedMileageSteperMarks = [
  { value: '1000' },
  { value: '1500' },
  { value: '1700' },
  { value: '2000' },
  { value: '2500' },
  { value: '2600' },
  { value: '3000' },
  { value: '3500' },
  { value: '4000' },
  { value: '5000' },
]

export const flowType = {
  ESTIMATED_MILEAGE: 'EstimatedMileage',
  OLD_FLOW: 'OldFlow',
  BASE_MILEAGE: 'BaseMileage',
  MONTHLY_DEFAULT: 'MonthlyDefault',
}

export const stateDisclaimers = {
  MD: 'Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
  PA: 'Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.',
}
